import { Dispatch } from 'redux';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { UnknownFunction } from '@eon-home/react-library';

import {
    ResponseError,
    ElectricCarApi,
    ScheduleChargingModel,
    GenericSmartChargingModel,
    ElectricCarResponseModelV1,
    GenericSmartChargingModelModeEnum,
    GoogleAPILocationRequestModel as ElectricCarAddress,
    ElectricCarChargingModeCommandModelV1ActionEnum as CommandsEnum,
    PvBatteryApi,
    ElectricCarHistorySessionV2ResponseModel,
} from '@swagger-http';

import { store } from '@src/index';
import { Scope } from '@tools/enums';
import {
    AggregatedDataUserState,
    ChargingModeModel,
    EmobilityAction,
} from '@store/types';
import { setSuccessToast } from '@store/actions';
import { useElectricCarConfigId } from '@store/selectors';
import { handleHistoricalEmobilityData } from '@store/actions';
import {
    EmobilityActionTypes,
    HistoricalResolution,
    LiveDataActionTypes,
} from '@store/enums';
import {
    handleError,
    checkForScopes,
    createRequestConfiguration,
    getResolution,
    getComparisonInterval,
    getRequestDates,
} from '@tools/utils';

const {
    // SolarAssistedCharging,
    Schedule,
    // GridFriendlyCharging,
    // DynamicPriceCharging,
    // HomePlugAndCharge,
} = GenericSmartChargingModelModeEnum;

export interface EmobilityAddressDataProps {
    configId: string;
    address: ElectricCarAddress;
    isAddressSet: boolean;
}

export const getElectricCarData =
    () =>
    (dispatch: Dispatch<any>): Promise<EmobilityAction | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_READ])) {
            return Promise.resolve();
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarListElectricCars()
            .then((data: ElectricCarResponseModelV1[]) => {
                return dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_DEVICE_DATA,
                    payload: {
                        electricCar: data[0],
                    },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error when getting the electric car data:',
                );

                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_ERROR,
                    payload: {
                        error: e?.response,
                    },
                });
            });
    };

const setEvStateLoading = (evStateLoading: boolean) => ({
    type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
    payload: {
        evStateLoading,
    },
});

const setEvCommandError = (error: boolean) => ({
    type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
    payload: {
        commandError: error,
    },
});

export const sendElectricCarCommand =
    (configId: string, command: CommandsEnum) =>
    (dispatch: Dispatch<any>): Promise<EmobilityAction | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE])) {
            return Promise.resolve();
        }

        dispatch(setEvCommandError(false));
        dispatch({
            type: LiveDataActionTypes.ELECTRIC_CAR_TELEMETRIES,
            payload: {
                evStateLoading: true,
                lastCommand: command,
            },
        });

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetChargingMode({
                configId,
                electricCarChargingModeCommandModelV1: {
                    action: command,
                },
            })
            .then((data) => {
                if (data.failureReason) {
                    dispatch(setEvCommandError(true));
                    dispatch(setEvStateLoading(false));
                    return;
                }
            })
            .catch(async (e: ResponseError) => {
                dispatch(setEvCommandError(true));
                dispatch(setEvStateLoading(false));

                await handleError(
                    e,
                    'Error when calling electricCarSetChargingMode:',
                );
            });
    };

export const setAddress =
    (addressData: EmobilityAddressDataProps) =>
    (dispatch: Dispatch): Promise<boolean | void> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE])) {
            return Promise.resolve(false);
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetAddress({
                configId: addressData.configId,
                googleAPILocationRequestModel: addressData.address,
            })
            .then(() => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_ADDRESS,
                });
                if (
                    checkForScopes([Scope.EMOBILITY_FLEX_CHARGING_WRITE]) &&
                    !addressData.isAddressSet
                ) {
                    dispatch(
                        setElectricCarSmartSchedulingMode(
                            addressData.configId,
                            {
                                start: '2200',
                                end: '0700',
                                active: true,
                            } as ScheduleChargingModel,
                        ),
                    );
                }

                return true;
            })
            .catch(async (e: ResponseError) => {
                // address does not have to be changed when backend returns error
                await handleError(e, 'Error when setting address:');

                return false;
            });
    };

export const getElectricCarChargingModesData =
    (configId: string, silent = true) =>
    (dispatch: Dispatch): Promise<void> => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ])) {
            return Promise.resolve();
        }

        if (!silent) {
            dispatch(setElectricCarChargingModesLoading(true));
        }

        dispatch(setElectricCarChargingModesError(false));

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarGetSmartChargingModes({ configId })
            .then((data: GenericSmartChargingModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_DATA,
                    payload: {
                        data,
                    },
                });
            })
            .catch(async (e) => {
                if (e.response.status !== 404) {
                    await handleError(e, `Error calling GetSmartChargingMode:`);

                    dispatch(setElectricCarChargingModesError(true));
                }
            })
            .finally(() => dispatch(setElectricCarChargingModesLoading(false)));
    };

export const setElectricCarChargingModesError =
    (error: boolean) => (dispatch: Dispatch) =>
        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_ERROR,
            payload: {
                error,
            },
        });

export const setElectricCarChargingModesLoading =
    (loading: boolean) => (dispatch: Dispatch) =>
        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_LOADING,
            payload: {
                loading,
            },
        });

export const setElectricCarChargingModeError =
    (mode: GenericSmartChargingModelModeEnum, error: ResponseError) =>
    (dispatch: Dispatch) => {
        let hasMatch = false;
        const data = store
            .getState()
            .emobility.electricCarData.chargingModes.data.map((item) => {
                if (!hasMatch) {
                    hasMatch = item.mode === mode;
                }

                return {
                    ...item,
                    error: hasMatch ? error : item.error,
                };
            });

        if (!hasMatch) {
            data.push({
                mode,
                error,
                parameters: { active: false },
            });
        }

        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODE_ERROR,
            payload: {
                data,
            },
        });
    };

export const toggleElectricCarChargingModes =
    (mode: GenericSmartChargingModelModeEnum, isActive: boolean) =>
    (dispatch: Dispatch) => {
        const data = store
            .getState()
            .emobility.electricCarData.chargingModes.data.map((item) => ({
                ...item,
                parameters: {
                    ...item.parameters,
                    active: item.mode === mode ? isActive : false,
                },
            }));

        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_SET_CHARGING_MODES_DATA,
            payload: {
                data,
            },
        });
    };

export const onSuccessfulElectricCarChargingModeChange =
    (configId: string) => (dispatch: Dispatch) => {
        dispatch(setSuccessToast());
        dispatch(getElectricCarChargingModesData(configId));
    };

export const setElectricCarSmartSchedulingMode =
    (
        configId: string,
        scheduleChargingModel: ScheduleChargingModel,
        callback?: UnknownFunction,
    ) =>
    (dispatch: Dispatch) => {
        if (!checkForScopes([Scope.EMOBILITY_SMART_CHARGING_WRITE])) {
            return Promise.resolve();
        }

        dispatch(
            toggleElectricCarChargingModes(
                Schedule,
                scheduleChargingModel.active,
            ),
        );

        if (typeof callback === 'function') {
            callback();
        }

        return new ElectricCarApi(createRequestConfiguration())
            .electricCarSetSmartChargingModeToSchedule({
                configId,
                scheduleChargingModel,
                takeInstantEffect: 'true',
            })
            .then(() => {
                dispatch(onSuccessfulElectricCarChargingModeChange(configId));
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    `Error calling SetSmartChargingModeToSchedule :`,
                );

                dispatch(setElectricCarChargingModeError(Schedule, e));
            });
    };

export const useSetElectricCarChargingMode = (
    mode: GenericSmartChargingModelModeEnum,
) => {
    const dispatch = useDispatch();
    const configId = useElectricCarConfigId();

    return useCallback(
        (parameters: ChargingModeModel, onComplete?: UnknownFunction): void => {
            if (!configId) {
                return;
            }

            switch (mode) {
                case Schedule:
                    //case GridFriendlyCharging:
                    dispatch(
                        setElectricCarSmartSchedulingMode(
                            configId,
                            parameters as ScheduleChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                /*
                case SolarAssistedCharging:
                    dispatch(
                        setSolarAssistedChargingMode(
                            configId,
                            parameters as SolarAssistedChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case DynamicPriceCharging:
                    dispatch(
                        setDynamicPriceChargingMode(
                            configId,
                            parameters as DynamicPriceChargingModel,
                            onComplete,
                        ),
                    );
                    break;
                case HomePlugAndCharge:
                    dispatch(
                        setPlugAndChargeMode(
                            configId,
                            parameters as PlugAndChargeModel,
                        ),
                    );*/
            }
        },
        [mode, configId, dispatch],
    );
};

export const getHistoricalElectricCarData = async (
    date: Date,
    res: HistoricalResolution,
    userState: AggregatedDataUserState,
    dispatch: Dispatch,
) => {
    if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_READ])) {
        return Promise.resolve({ prevDay: {} });
    }

    let error = false;

    const emobilityAPI = new PvBatteryApi(createRequestConfiguration());
    const resolution = getResolution(res);
    const comparisonInterval = getComparisonInterval(res);
    const { startDate, endDate } = getRequestDates(date, res, false);

    const data = await emobilityAPI
        .pvBatteryGetElectricCarHistoryV2({
            fromDate: startDate,
            toDate: endDate,
            interval: resolution,
            comparisonInterval,
        })
        .catch(async (e: ResponseError) => {
            await handleError(e, 'Error getting electric car historical data:');

            error = true;

            return [];
        });

    return handleHistoricalEmobilityData(
        date,
        res,
        error,
        dispatch,
        data,
        userState,
    );
};

export const getElectricCarChargingHistory =
    (fromDate: string, toDate: string) =>
    (dispatch: Dispatch): Promise<any> => {
        if (!checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_READ])) {
            return Promise.resolve();
        }

        dispatch({
            type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY,
            payload: {
                error: false,
                loading: true,
            },
        });

        return new PvBatteryApi(createRequestConfiguration())
            .pvBatteryGetElectricCarHistorySessionsV2({
                fromDate,
                toDate,
            })
            .then((data: ElectricCarHistorySessionV2ResponseModel[]) => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY,
                    payload: { data },
                });
            })
            .catch(async (e: ResponseError) => {
                await handleError(
                    e,
                    'Error getting electric car charging history:',
                );

                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY_ERROR,
                    payload: {
                        error: true,
                    },
                });
            })
            .finally(() => {
                dispatch({
                    type: EmobilityActionTypes.ELECTRIC_CAR_GET_CHARGING_HISTORY_LOADING,
                    payload: {
                        loading: false,
                    },
                });
            });
    };
