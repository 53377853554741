/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Dashboard - API
 * This API provides access and control function for smart equipped homes. For near real-time information please use this API here https://sse-hems-dev2.eon.ooo/docs/. `[NOTE]` The access token for the current user session is also valid for the real-time API utilising SSE (Server Sent Event)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BadRequest,
  ElectricCarChargingModeCommandModelV1,
  ElectricCarControlChargingResponseModelV1,
  ElectricCarResponseModelV1,
  Forbidden,
  GenericSmartChargingModel,
  GoogleAPILocationRequestModel,
  InternalServerError,
  NotFound,
  ScheduleChargingModel,
  Unauthorized,
  UnprocessableEntity,
} from '../models';

export interface ElectricCarGetElectricCarRequest {
    configId: string;
}

export interface ElectricCarGetSmartChargingModesRequest {
    configId: string;
}

export interface ElectricCarSetAddressRequest {
    configId: string;
    googleAPILocationRequestModel: GoogleAPILocationRequestModel;
}

export interface ElectricCarSetChargingModeRequest {
    configId: string;
    electricCarChargingModeCommandModelV1: ElectricCarChargingModeCommandModelV1;
}

export interface ElectricCarSetSmartChargingModeToScheduleRequest {
    configId: string;
    scheduleChargingModel: ScheduleChargingModel;
    takeInstantEffect?: string;
}

/**
 * 
 */
export class ElectricCarApi extends runtime.BaseAPI {

    /**
     * Find a electric car by configId
     */
    async electricCarGetElectricCarRaw(requestParameters: ElectricCarGetElectricCarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ElectricCarResponseModelV1>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling electricCarGetElectricCar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/electric-car/{configId}`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Find a electric car by configId
     */
    async electricCarGetElectricCar(requestParameters: ElectricCarGetElectricCarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ElectricCarResponseModelV1> {
        const response = await this.electricCarGetElectricCarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all the smart charging modes
     */
    async electricCarGetSmartChargingModesRaw(requestParameters: ElectricCarGetSmartChargingModesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GenericSmartChargingModel>>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling electricCarGetSmartChargingModes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/electric-car/{configId}/smartCharging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all the smart charging modes
     */
    async electricCarGetSmartChargingModes(requestParameters: ElectricCarGetSmartChargingModesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GenericSmartChargingModel>> {
        const response = await this.electricCarGetSmartChargingModesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of electric cars
     */
    async electricCarListElectricCarsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectricCarResponseModelV1>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/electric-car`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of electric cars
     */
    async electricCarListElectricCars(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectricCarResponseModelV1>> {
        const response = await this.electricCarListElectricCarsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Save address in long/lat format
     */
    async electricCarSetAddressRaw(requestParameters: ElectricCarSetAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling electricCarSetAddress.');
        }

        if (requestParameters.googleAPILocationRequestModel === null || requestParameters.googleAPILocationRequestModel === undefined) {
            throw new runtime.RequiredError('googleAPILocationRequestModel','Required parameter requestParameters.googleAPILocationRequestModel was null or undefined when calling electricCarSetAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/electric-car/address/{configId}`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.googleAPILocationRequestModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save address in long/lat format
     */
    async electricCarSetAddress(requestParameters: ElectricCarSetAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.electricCarSetAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Send charging command
     */
    async electricCarSetChargingModeRaw(requestParameters: ElectricCarSetChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ElectricCarControlChargingResponseModelV1>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling electricCarSetChargingMode.');
        }

        if (requestParameters.electricCarChargingModeCommandModelV1 === null || requestParameters.electricCarChargingModeCommandModelV1 === undefined) {
            throw new runtime.RequiredError('electricCarChargingModeCommandModelV1','Required parameter requestParameters.electricCarChargingModeCommandModelV1 was null or undefined when calling electricCarSetChargingMode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/electric-car/{configId}/charging`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.electricCarChargingModeCommandModelV1,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Send charging command
     */
    async electricCarSetChargingMode(requestParameters: ElectricCarSetChargingModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ElectricCarControlChargingResponseModelV1> {
        const response = await this.electricCarSetChargingModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Set the smart charging mode to \"schedule\"
     */
    async electricCarSetSmartChargingModeToScheduleRaw(requestParameters: ElectricCarSetSmartChargingModeToScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleChargingModel>> {
        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling electricCarSetSmartChargingModeToSchedule.');
        }

        if (requestParameters.scheduleChargingModel === null || requestParameters.scheduleChargingModel === undefined) {
            throw new runtime.RequiredError('scheduleChargingModel','Required parameter requestParameters.scheduleChargingModel was null or undefined when calling electricCarSetSmartChargingModeToSchedule.');
        }

        const queryParameters: any = {};

        if (requestParameters.takeInstantEffect !== undefined) {
            queryParameters['takeInstantEffect'] = requestParameters.takeInstantEffect;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/electric-car/{configId}/smartCharging/schedule`.replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.scheduleChargingModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Set the smart charging mode to \"schedule\"
     */
    async electricCarSetSmartChargingModeToSchedule(requestParameters: ElectricCarSetSmartChargingModeToScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleChargingModel> {
        const response = await this.electricCarSetSmartChargingModeToScheduleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
