import { useMemo } from 'react';

import {
    ScheduleChargingModel,
    GenericSmartChargingModelModeEnum,
} from '@swagger-http';

import { checkForScopes } from '@tools/utils';
import { ChargingModeData } from '@store/types';
import { MarketingTileModel } from '@tools/types';
import { MarketingTileTypes, Scope } from '@tools/enums';
import {
    useAppSelector,
    useHasEmobilityTokenExpired,
    useEmobilityReadingStartDate,
} from '@store/selectors';

const {
    Schedule,
    DynamicPriceCharging,
    GridFriendlyCharging,
    SolarAssistedCharging,
    HomePlugAndCharge,
} = GenericSmartChargingModelModeEnum;

export const useHasElectricCar = () =>
    !!useAppSelector(
        (state) => state.emobility.electricCarData?.electricCar?.configId,
    );

// TODO EONFEH-13779: check this implementation
export const useIsElectricCarCharging = () =>
    useAppSelector(
        (state) =>
            state.liveData.telemetries.emobility.electricCar
                .isElectricCarCharging,
    );

export const useIsElectricCarOnline = () =>
    useAppSelector(
        (state) =>
            state.liveData.telemetries.emobility.electricCar
                .isElectricCarOnline,
    );

// prettier-ignore
export const useElectricCarConfigId = () => useAppSelector((state) => state.emobility.electricCarData?.electricCar?.configId) || '';

export const useElectricCarConnectButton = (
    marketingTiles: MarketingTileModel[],
): boolean => {
    const tile = marketingTiles.find(
        (item) => item.type === MarketingTileTypes.ELECTRIC_CAR,
    );

    return (
        !!tile &&
        tile.shouldRender &&
        checkForScopes([Scope.ENERGYDEVICES_ELECTRIC_CAR_WRITE])
    );
};

// TODO EONFEH-13869: this is always true because we get '' from the backend in any case -> rethink this
export const useHasElectricCarTokenExpired = () => {
    const tokenExpiryDate = useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceGateway
                ?.clientAccess?.tokenExpiryDate,
    );

    return useHasEmobilityTokenExpired(tokenExpiryDate);
};

export const useElectricCarBrand = () =>
    useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceConfiguration
                ?.information?.brand ?? '',
    );

export const useElectricCarModel = () =>
    useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceConfiguration
                ?.information?.model ?? '',
    );

export const useIsAddressSet = () =>
    useAppSelector(
        (state) =>
            state.emobility.electricCarData?.electricCar?.deviceConfiguration
                ?.isAddressSet,
    );

export const useElectricCarReadingStartDate = () => {
    const readingStartDate = useAppSelector(
        (state) =>
            state.emobility.electricCarData.electricCar?.deviceConfiguration
                ?.readingStartDate,
    );

    return useEmobilityReadingStartDate(readingStartDate);
};

export const useElectricCarChargingModes = () => {
    const data = useAppSelector(
        (state) => state.emobility.electricCarData.chargingModes.data,
    );
    const permissions = useElectricCarChargingModesPermissions();

    return data.filter(
        (item) => item.mode !== GridFriendlyCharging && permissions[item.mode],
    );
};

export const useElectricCarChargingModesErrorAndLoading = () => {
    const { error, loading } = useAppSelector(
        (state) => state.emobility.electricCarData.chargingModes,
    );
    return { error, loading };
};

export const useElectricCarSmartSchedulingData = () => {
    const data = useAppSelector((state) =>
        state.emobility.electricCarData.chargingModes.data.find(
            (item) => item.mode === Schedule,
        ),
    ) as ChargingModeData<ScheduleChargingModel> | undefined;

    return useMemo(
        () => ({
            active: !!data?.parameters.active,
            hasData: !!data?.parameters,
            defaultEnd: data?.parameters.end || '1200',
            defaultStart: data?.parameters.start || '0000',
        }),
        [data?.parameters],
    );
};

export const useElectricCarLastChargingSessionData = () =>
    useAppSelector(
        (state) => state.insights?.emobility?.electricCar.lastChargingSession,
    );

export type ElectricCarChargingModesPermissions = {
    [Schedule]: boolean;
    [SolarAssistedCharging]: boolean;
    [GridFriendlyCharging]: boolean;
    [DynamicPriceCharging]: boolean;
    [HomePlugAndCharge]: boolean;
};
export const useElectricCarChargingModesPermissions =
    (): ElectricCarChargingModesPermissions => ({
        [Schedule]: checkForScopes([Scope.EMOBILITY_SMART_CHARGING_READ]),
        [SolarAssistedCharging]: checkForScopes([
            Scope.EMOBILITY_SOLAR_CHARGING_READ,
        ]),
        [GridFriendlyCharging]: checkForScopes([
            Scope.EMOBILITY_SMART_CHARGING_READ,
        ]),
        [DynamicPriceCharging]: checkForScopes([
            Scope.EMOBILITY_DYNAMIC_PRICE_CHARGING_READ,
        ]),
        [HomePlugAndCharge]: checkForScopes([
            Scope.EMOBILITY_SMART_CHARGING_READ,
        ]),
    });

export const useElectricCarChargingModesCount = () => {
    const permissions = useElectricCarChargingModesPermissions();
    return Object.keys(permissions).reduce(
        (acc: boolean[], curr: GenericSmartChargingModelModeEnum) => {
            if (
                permissions[curr] &&
                ![
                    GenericSmartChargingModelModeEnum.GridFriendlyCharging,
                    GenericSmartChargingModelModeEnum.HomePlugAndCharge,
                ].includes(curr)
            ) {
                acc.push(true);
            }

            return acc;
        },
        [],
    ).length;
};
